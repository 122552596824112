<template>
  <v-app>
    <v-container>
      <v-dialog data-app v-model="modalOpened" max-width="400px">
        <v-card v-if="createdLink">
          <v-icon class="btn-close-modal" @click="modalOpened=false" size="36">mdi-close</v-icon>
          <v-card-title>Ссылка готова!</v-card-title>
          <v-card-text>
            <v-text-field ref="createdLink" readonly :value="createdLink"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn v-if="copied">
              <v-icon>mdi-check</v-icon>
              Ссылка скопирована
            </v-btn>
            <v-btn v-else @click="copy">Скопировать</v-btn>
            <v-btn style="background: #eee !important;" @click="modalOpened = false">Закрыть</v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-else>
          <v-icon class="btn-close-modal" @click="modalOpened=false" size="36">mdi-close</v-icon>
          <v-card-title>Создание ссылки</v-card-title>
          <v-card-text>
            <v-subheader>Ссылка, которую надо укоротить</v-subheader>
            <v-textarea outlined v-model="newLink"/>
            <v-subheader>Комментарий</v-subheader>
            <v-textarea outlined v-model="comment"/>
          </v-card-text>
          <v-card-actions>
            <v-btn :loading="loading" @click="save">Получить короткую ссылку</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-card>
        <v-card-title>Короткие ссылки</v-card-title>
        <v-card-text>
          <v-btn @click="open">Новая ссылка</v-btn>
          <v-data-table
              hover
              :items="rows"
              :headers="headers"
              :loading="loading"
              :footer-props="{'items-per-page-options':[-1]}"
              no-data-text="Информации нет"
              loading-text="Загрузка..."
              mobile-breakpoint="0">
            <template v-slot:item.short="{item, index}">
              <td>
                {{ item.short }}
              </td>
            </template>
            <template v-slot:item.link="{item, index}">
              <td>
                <a target="_blank" :href="item.link">{{ item.link }}</a>
              </td>
            </template>
            <template v-slot:item.datetime_create="{item, index}">
              <td>
                {{ item.datetime_createObj.str }}
              </td>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>

export default {
  name: "ShortLinks",
  data() {
    let headers = [
      {text: 'Короткая ссылка', value: 'short'},
      {text: 'Комментарий', value: 'comment'},
      {text: 'Редирект на', value: 'link'},
      {text: 'Дата создания', value: 'datetime_create'},
    ];
    return {
      rows: [],
      headers,
      modalOpened: false,
      newLink: '',
      comment: '',
      createdLink: '',
      loading: true,
      copied: false,
    }
  },
  methods: {
    load() {
      this.loading = true;
      this.$store.state.server.request('shortLink/get', {}, (data) => {
        this.loading = false;
        this.rows = data.response;
      }, (data) => {
        this.loading = false;
        if (data && data.error) this.$root.notify(data.error, 'error')
      })
    },
    copy() {
      let input = this.$refs.createdLink.$el.getElementsByTagName('input')[0];
      input.focus();
      input.select();
      this.copied = false;
      try {
        this.copied = window.document.execCommand('copy')
      } catch (e) {
        this.copied = false;
      }
      if (this.copied) setTimeout(() => this.copied = false, 2000);
      // this.$refs.createdLink.focus();$refs.createdLink.select();window.document.execCommand('copy')
    },
    open() {
      this.modalOpened = true;
      this.copied = false;
      this.createdLink = '';
      this.newLink = '';
      this.comment = '';
    },
    save() {
      if (!this.newLink) return this.$root.notify('Введите ссылку', 'warning')
      this.saveLoading = true;
      this.$store.state.server.request('shortLink/create', {link: this.newLink, comment: this.comment}, (data) => {
        this.saveLoading = false;
        this.createdLink = data.response.short;
        this.load();
      }, (data) => {
        this.loading = false;
        if (data && data.error) this.$root.notify(data.error, 'error')
      })
    }
  },
  mounted() {
    this.load()
  }
}
</script>
